import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#265b70';
const footerRelatedLinks = [
	{
		title: 'Rachis Clinic',
		url: '/fr/projets/rachis-clinic/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/crowsnest/crowsnest_hero.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/crowsnest/crowsnest_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_0: file(
					relativePath: {
						eq: "projects/avriontx/avrion_brand_dna.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_3.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_4.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_5.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_6.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_7.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_8.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_9.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: { eq: "projects/crowsnest/crowsnest_10.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/crowsnest-properties-branding/',
					lang: 'en',
				},
			]}
			title="Crowsnest Properties Branding - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Crowsnest Properties"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Identifier des projets immobiliers à l'état brut,
							pour les transformer en diamants à forte valeur
							ajoutée.{' '}
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Positonnement de marque',
								'Branding',
								'Identité visuelle',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Quoi</h3>
						<p>
							Crowsnest est une société californienne spécialisée
							dans l’achat, la valorisation et la gestion de biens
							immobiliers industriels. Ses besoins en
							développement ont poussé la société à réimaginer
							complètement sa marque pour traduire de manière plus
							efficace ses spécificités et atteindre son public à
							distance.{' '}
						</p>

						<h3>Comment</h3>
						<p>
							Nos workshops stratégiques de marque ont permis de
							spécifier l’ADN fondamental de la société pour
							réussir, sur la base de ces éléments, la création de
							l’identité visuelle et du logo. La création de cette
							identité visuelle forte et consistante s’inspire de
							codes graphiques new-yorkais, faisant référence à la
							cible de clientèle principale. L’ensemble du travail
							de positionnement et de création participe ainsi à
							l'accélération du développement de l'entreprise.
						</p>
					</div>
				</div>

				<Video
					className="col-full"
					src="9a3f47b75634263f74623d7a67cb64eb"
					alt="Animation du symbole"
				/>

				<Image
					className="col-full"
					{...data.image_1.childImageSharp}
					alt="Inspiration du logo"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Des bâtiments de qualité dans des endroits stratégiques"
					more="Le symbole du logo représente la capacité de Crowsnest Properties à identifier les meilleures localisations (épingle), ces perles rares à l'état brut qui se transforment en diamants entre leurs mains. Le travail du symbole en trois dimensions communique quant à lui les trois secteurs majeurs de la société."
				/>

				<Image
					className="col-full"
					{...data.image_2.childImageSharp}
					alt="Campagne d'affichage en ville"
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_3.childImageSharp}
						alt="Inspiration design mobile"
					/>
					<Image
						{...data.image_4.childImageSharp}
						alt="Inspiration design sur ordinateur portable"
					/>
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_5.childImageSharp}
					alt="Panneau d'affichage en hauteur"
				/>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image
							{...data.image_6.childImageSharp}
							alt="Papeterie de Crowsnest"
						/>
						<Image
							{...data.image_7.childImageSharp}
							alt="Background d'habillage"
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>Un branding solide et percutant</h3>
						<p>
							L'identité appliquée réinterprète des codes
							graphiques élégants de tramage dans une application
							certes joueuse, mais surtout percutante. La
							simplicité du symbole offre le bénéfice d'une
							reconnaissance directe et a l’avantage d'être facile
							à intégrer dans les différents contextes.
						</p>
					</div>
				</Column>

				<Column>
					<div className="column__text column__sticky text-content">
						<h3>
							La simplicité du symbole bénéficie d'une
							reconnaissance directe.{' '}
						</h3>
						<p>
							Mais aussi d'une facilité d'intégration dans
							différents contextes potentiels.
						</p>
					</div>
					<div className="column__media text-content">
						<Image {...data.image_9.childImageSharp} alt="" />
						<Image {...data.image_10.childImageSharp} alt="" />
					</div>
				</Column>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
